<template>
  <div class="form-group">
    <pulse-loader
      :loading="true"
      v-if="typingEffect.before"
    ></pulse-loader>
    <p v-if="data.question_before && showBefore">
      {{ data.question_before }}
    </p>

    <pulse-loader
      :loading="true"
      v-if="typingEffect.question"
    ></pulse-loader>

    <el-row
      align="middle"
      v-if="showQuestion"
    >
      <el-col :span="showLabel && !isDefalutPos ? 8 : 24">
        <label
          for="text"
          :style="getLabelStyles"
        >
          {{ getQuestionIndex(data.key) }}. {{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </el-col>
      <el-col
        :span="24"
        v-if="data.description"
      >
        <p
          for="text"
          :style="getLabelStyles"
        >{{ data.description }}</p>
      </el-col>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <!-- Radio Button Options -->
        <el-radio-group
          v-model="form[data.key]"
          :disabled="checkReadonly()"
          v-if="!isView && data.question_type == 'YES_OR_NO'"
          @change="questionAnswered(false)"
        >
          <el-radio
            label="YES"
            border
          >YES</el-radio>
          <el-radio
            label="NO"
            border
          >NO</el-radio>
        </el-radio-group>
        <!-- MCQ Checkbox Options -->
        <el-checkbox-group
          v-model="form[data.key]"
          :disabled="checkReadonly()"
          v-if="showMSQ"
          :max="data.max_selection || undefined"
          @change="questionAnswered(false)"
        >
          <ol :class="data.question_options_format.toLowerCase()">
            <li
              v-for="(option, index) of data.options"
              :key="index"
            >
              <el-checkbox
                :value="option"
                :label="option"
                border
              >
                {{ String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)) }}. {{ option }}</el-checkbox>
            </li>
            <li v-if="data.has_other_option">
              <el-checkbox
                value="others"
                label="Others"
                border
              >
                {{ String.fromCharCode(data.options.length + 65) }}.
                Others</el-checkbox>
            </li>
          </ol>
        </el-checkbox-group>
        <div
          v-if="data.has_other_option && showMSQ && otherOptionSelected"
          class="mt-1"
        >
          {{
            data.other_option_label
              ? data.other_option_label
              : "Enter other option"
          }}
          <el-input v-model="form[data.key + '/otheroption']"> </el-input>
        </div>
        <el-select
          v-model="form[data.key]"
          :disabled="checkReadonly()"
          v-if="
            !isView &&
            data.question_type == 'MCQ' &&
            data.question_options_format &&
            data.question_options_format == 'DROP_DOWN'
          "
          :max="data.max_selection || undefined"
          multiple
          :multiple-limit="data.max_selection || undefined"
          collapse-tags
          @change="questionAnswered(false)"
        >
          <el-option
            v-for="(option, index) of data.options"
            :key="index"
            :value="option"
            :label="option"
          ></el-option>
        </el-select>
        <!-- <pulse-loader :loading="true" v-if="typingEffect.after"></pulse-loader> -->
        <p v-if="data.question_after && showAfter">
          {{ data.question_after }}
        </p>

        <!-- <p
          style="margin-top: 5px"
          :id="`currentQuestion` + questionIndex"
          title="scrollingPart"
        ></p> -->
        <template v-if="
            !isView &&
            (data.question_type == 'TEXT' ||
              (data.validations && !data.validations.required) || resetQuestionireForm)
          ">
          <el-input
            v-if="data.question_type == 'TEXT'"
            :style="getStyle"
            type="textarea"
            placeholder="Your Answer"
            v-model="form[data.key]"
            :disabled="checkReadonly()"
          >
          </el-input>
          <br />
          <el-button
            @click="gotoPrevField"
            size="mini"
            :loading="loadingAfter"
            v-if="
              isCurrentField &&
              interactiveQuestionsModeType == 'horizontal' &&
              nextIndex == lastInteactiveQuestionIndex &&
              currentQuestionIndexs[0] != 0
            "
            class="mt-1"
          >
            Prev</el-button>
          <el-button
            :disabled="isFieldCompleted"
            @click="questionAnswered(true)"
            size="mini"
            v-if="isCurrentField"
            :loading="loadingAfter"
            class="mt-1"
          >
            Next</el-button>
        </template>
      </el-col>

      <el-col
        :span="24"
        v-if="isView"
      >
        <el-input
          v-if="data.question_type == 'TEXT'"
          :style="getStyle"
          type="textarea"
          :placeholder="data.placeholder || data.label"
          v-model="form[data.key]"
          readonly
        >
        </el-input>

        <el-radio-group
          v-model="form[data.key]"
          v-if="data.question_type == 'YES_OR_NO'"
          :fill="'#898ff5'"
          :disabled="true"
          @change="questionAnswered(false)"
        >
          <el-radio
            label="YES"
            border
          >YES</el-radio>
          <el-radio
            label="NO"
            border
          >NO</el-radio>
        </el-radio-group>

        <el-checkbox-group
          v-model="form[data.key]"
          v-if="data.question_type == 'MCQ'"
          :max="data.max_selection || undefined"
          :disabled="true"
          @change="questionAnswered(false)"
        >
          <el-checkbox
            v-for="(option, index) of data.options"
            :value="option"
            :key="index"
            :label="option"
            border
          >
            {{ String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)) }}. {{ option }}</el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import QuetionFlowHelper from "@/mixins/QuetionFlowHelper";
// import VueScrollTo from "vue-scrollto";

export default {
  mixins: [userPermissionsHelper, QuetionFlowHelper],
  components: {
    PulseLoader
  },
  props: [
    "data",
    "form",
    "isView",
    "hasLabel",
    "lastInteactiveQuestionIndex",
    "nextIndex",
    "interactiveQuestionsModeType",
    "currentQuestionIndexs",
    "fieldsData",
    "resetQuestionireForm"
  ],
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "";
    },
    isFieldCompleted() {
      if (
        this.data &&
        this.data.key &&
        this.data.validations &&
        this.data.validations.required
      ) {
        return !(
          this.form[this.data.key] &&
          this.form[this.data.key] != "" &&
          this.form[this.data.key] != null &&
          this.form[this.data.key] != undefined
        );
      }
      return false;
    },
    showMSQ() {
      return (
        !this.isView &&
        this.data.question_type == "MCQ" &&
        this.data.question_options_format &&
        this.data.question_options_format != "DROP_DOWN"
      );
    },
    isCurrentField() {
      return this.lastInteactiveQuestionIndex === this.nextIndex;
    }
  },
  data() {
    return {
      isDefalutPos: true,
      showLabel: true,
      loadingAfter: false,
      loadingBefore: false,
      questionDetails: {},
      questionIndex: 0,
      otherOptionSelected: false,
      isAnswered: false
    };
  },
  mounted() {
    this.questionDetails = { ...this.data };
    let data = this.form[this.data.key];
    if (!this.form[this.data.key] && this.data.question_type == "MCQ") {
      this.$set(this.form, this.data.key, []);
      let otherOption = (data || []).find(e => e.toLowerCase() == "others");
      if (this.data.has_other_option && otherOption) {
        this.otherOptionSelected = true;
      }
    }
    if (
      this.data.has_other_option &&
      this.data.key &&
      !this.form[this.data.key + "/otheroption"] &&
      this.data.question_type == "MCQ"
    ) {
      this.$set(
        this.form,
        `${this.data.key}/otheroption`,
        this.form[this.data.key + "/otheroption"]
          ? this.form[this.data.key + "/otheroption"]
          : ""
      );
    }
    this.questionIndex = this.data.question_id;

    this.displayTypingEffect("before");
    this.displayBefore();
    this.displayTypingEffect("question");
    this.displayQuestion();
    this.displayOptions();
    this.scrollToBottom();
  },
  methods: {
    getQuestionIndex(key) {
      if (key) {
        const index = this.fieldsData
          .filter(e => e.type == "FIELD")
          .findIndex(e => e.key == key);
        if (index > -1) {
          return index + 1;
        }
      }
      return "";
    },
    gotoPrevField() {
      this.$emit("goto-prev");
    },
    checkReadonly() {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        if (this.data.styles.labelStyle == "right") {
          this.isDefalutPos = false;
        }
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },

    scrollToBottom() {
      // setTimeout(() => {
      //   VueScrollTo.scrollTo("#currentQuestion", 100, {
      //     container: "#formBuilder",
      //   });
      // });
    },
    loadAfterText() {
      this.loadingAfter = true;
      setTimeout(() => {
        this.loadingAfter = false;
      }, 1000);
    },
    goToNextQuestion() {
      this.loadAfterText();
      if (
        this.lastInteactiveQuestionIndex !== this.nextIndex ||
        this.isAnswered
      ) {
        return;
      }
      this.displayTypingEffect("after");
      this.displayAfter();
      this.isAnswered = true;
      this.$emit("goto-next");
    },
    questionAnswered(evt) {
      let data = this.form[this.data.key];
      if (this.data.has_other_option && this.data.question_type == "MCQ") {
        let otherOption = (data || []).find(e => e.toLowerCase() == "others");
        if (otherOption) {
          this.otherOptionSelected = true;
          if(this.form[this.data.key + '/otheroption']){
            this.goToNextQuestion();
          }
        } else {
          this.otherOptionSelected = false;
          if (!this.isFieldCompleted) {
            if (
              this.showMSQ &&
              this.data.max_selection > 0 &&
              this.form[this.data.key].length == this.data.max_selection
            ) {
              this.goToNextQuestion();
            } else if (!this.showMSQ) {
              this.goToNextQuestion();
            }
          }
        }
      } else {
        if (!this.isFieldCompleted) {
          if (
            this.data &&
            this.data.validations &&
            !this.data.validations.required
          ) {
            if (
              this.data.question_type == "MCQ" &&
              this.form[this.data.key] &&
              this.data.max_selection > 0 &&
              this.form[this.data.key].length == this.data.max_selection
            ) {
              this.goToNextQuestion();
            } else if (
              this.data.question_type == "MCQ" &&
              (!this.data.max_selection || this.data.max_selection == 0) &&
              (!this.form[this.data.key] ||
                (this.form[this.data.key] && !this.form[this.data.key].length))
            ) {
              this.goToNextQuestion();
            } else if (
              this.data.question_type == "YES_OR_NO" &&
              (this.form[this.data.key] == "YES" ||
                this.form[this.data.key] == "NO")
            ) {
              this.goToNextQuestion();
            } else if (evt) {
              this.goToNextQuestion();
            }
          } else if (
            this.showMSQ &&
            this.data.max_selection > 0 &&
            this.form[this.data.key].length == this.data.max_selection
          ) {
            this.goToNextQuestion();
          } else if (!this.showMSQ) {
            this.goToNextQuestion();
          }
        }
      }
    }
  },
  watch: {}
};
</script>
<style lang="scss">
.horizantal_list {
  display: inline-flex;
  gap: 10px;
  padding-left: 0;
  margin-top: 10px;
  @media (max-width:760px){
    flex-direction: column !important;
  }
}
.vertical_list {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding-left: 0;
  margin-top: 10px;
}

.el-select .el-select__tags>span {
    display: flex;
}
</style>