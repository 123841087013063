var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[(_vm.typingEffect.before)?_c('pulse-loader',{attrs:{"loading":true}}):_vm._e(),(_vm.data.question_before && _vm.showBefore)?_c('p',[_vm._v(" "+_vm._s(_vm.data.question_before)+" ")]):_vm._e(),(_vm.typingEffect.question)?_c('pulse-loader',{attrs:{"loading":true}}):_vm._e(),(_vm.showQuestion)?_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 8 : 24}},[_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(" "+_vm._s(_vm.getQuestionIndex(_vm.data.key))+". "+_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e()]),(_vm.data.description)?_c('el-col',{attrs:{"span":24}},[_c('p',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.description))])]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[(!_vm.isView && _vm.data.question_type == 'YES_OR_NO')?_c('el-radio-group',{attrs:{"disabled":_vm.checkReadonly()},on:{"change":function($event){return _vm.questionAnswered(false)}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('el-radio',{attrs:{"label":"YES","border":""}},[_vm._v("YES")]),_c('el-radio',{attrs:{"label":"NO","border":""}},[_vm._v("NO")])],1):_vm._e(),(_vm.showMSQ)?_c('el-checkbox-group',{attrs:{"disabled":_vm.checkReadonly(),"max":_vm.data.max_selection || undefined},on:{"change":function($event){return _vm.questionAnswered(false)}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('ol',{class:_vm.data.question_options_format.toLowerCase()},[_vm._l((_vm.data.options),function(option,index){return _c('li',{key:index},[_c('el-checkbox',{attrs:{"value":option,"label":option,"border":""}},[_vm._v(" "+_vm._s(String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)))+". "+_vm._s(option))])],1)}),(_vm.data.has_other_option)?_c('li',[_c('el-checkbox',{attrs:{"value":"others","label":"Others","border":""}},[_vm._v(" "+_vm._s(String.fromCharCode(_vm.data.options.length + 65))+". Others")])],1):_vm._e()],2)]):_vm._e(),(_vm.data.has_other_option && _vm.showMSQ && _vm.otherOptionSelected)?_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.data.other_option_label ? _vm.data.other_option_label : "Enter other option")+" "),_c('el-input',{model:{value:(_vm.form[_vm.data.key + '/otheroption']),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key + '/otheroption', $$v)},expression:"form[data.key + '/otheroption']"}})],1):_vm._e(),(
          !_vm.isView &&
          _vm.data.question_type == 'MCQ' &&
          _vm.data.question_options_format &&
          _vm.data.question_options_format == 'DROP_DOWN'
        )?_c('el-select',{attrs:{"disabled":_vm.checkReadonly(),"max":_vm.data.max_selection || undefined,"multiple":"","multiple-limit":_vm.data.max_selection || undefined,"collapse-tags":""},on:{"change":function($event){return _vm.questionAnswered(false)}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},_vm._l((_vm.data.options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option,"label":option}})}),1):_vm._e(),(_vm.data.question_after && _vm.showAfter)?_c('p',[_vm._v(" "+_vm._s(_vm.data.question_after)+" ")]):_vm._e(),(
          !_vm.isView &&
          (_vm.data.question_type == 'TEXT' ||
            (_vm.data.validations && !_vm.data.validations.required) || _vm.resetQuestionireForm)
        )?[(_vm.data.question_type == 'TEXT')?_c('el-input',{style:(_vm.getStyle),attrs:{"type":"textarea","placeholder":"Your Answer","disabled":_vm.checkReadonly()},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),_c('br'),(
            _vm.isCurrentField &&
            _vm.interactiveQuestionsModeType == 'horizontal' &&
            _vm.nextIndex == _vm.lastInteactiveQuestionIndex &&
            _vm.currentQuestionIndexs[0] != 0
          )?_c('el-button',{staticClass:"mt-1",attrs:{"size":"mini","loading":_vm.loadingAfter},on:{"click":_vm.gotoPrevField}},[_vm._v(" Prev")]):_vm._e(),(_vm.isCurrentField)?_c('el-button',{staticClass:"mt-1",attrs:{"disabled":_vm.isFieldCompleted,"size":"mini","loading":_vm.loadingAfter},on:{"click":function($event){return _vm.questionAnswered(true)}}},[_vm._v(" Next")]):_vm._e()]:_vm._e()],2),(_vm.isView)?_c('el-col',{attrs:{"span":24}},[(_vm.data.question_type == 'TEXT')?_c('el-input',{style:(_vm.getStyle),attrs:{"type":"textarea","placeholder":_vm.data.placeholder || _vm.data.label,"readonly":""},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.data.question_type == 'YES_OR_NO')?_c('el-radio-group',{attrs:{"fill":'#898ff5',"disabled":true},on:{"change":function($event){return _vm.questionAnswered(false)}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('el-radio',{attrs:{"label":"YES","border":""}},[_vm._v("YES")]),_c('el-radio',{attrs:{"label":"NO","border":""}},[_vm._v("NO")])],1):_vm._e(),(_vm.data.question_type == 'MCQ')?_c('el-checkbox-group',{attrs:{"max":_vm.data.max_selection || undefined,"disabled":true},on:{"change":function($event){return _vm.questionAnswered(false)}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},_vm._l((_vm.data.options),function(option,index){return _c('el-checkbox',{key:index,attrs:{"value":option,"label":option,"border":""}},[_vm._v(" "+_vm._s(String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)))+". "+_vm._s(option))])}),1):_vm._e()],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }